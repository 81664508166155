import React from 'react';
import PropTypes from 'prop-types';

const PostMetadata = ({ date, words, timeToRead }) =>
    <div className="metadata mb-2">
        <em className="post-date mr-5">{date}</em>
        <em className="text-muted">
            <span className="word-count">{words} words</span>
            <span className="separator"> | </span>
            <span className="read-time">{timeToRead} minute read</span>
        </em>
    </div>
;

PostMetadata.propTypes = {
    date: PropTypes.string.isRequired,
    words: PropTypes.number.isRequired,
    timeToRead: PropTypes.number.isRequired,
};

export default PostMetadata;
