import PropTypes from 'prop-types';
import { edges } from './utils/edges';

export const propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: edges,
        }).isRequired,
    }).isRequired,
};
