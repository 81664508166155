import React from 'react';

// Utilities
import { propTypes } from 'shapes/posts';
import { graphql } from 'gatsby';

// Components
import PostLink from 'components/Post-Link';
import Layout from 'components/Layout';

const PostsPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter(edge => !!edge.node.frontmatter.date)
        .map(({ node }) => (
            <PostLink key={node.frontmatter.path} post={node} />
        ));

    return (
        <Layout>
            <div className="page-content">{Posts}</div>
        </Layout>
    );
};

PostsPage.propTypes = propTypes;

export default PostsPage;

export const pageQuery = graphql`
    {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 1000
            filter: { frontmatter: { published: { ne: false } } }
        ) {
            edges {
                node {
                    html
                    excerpt(pruneLength: 250)
                    wordCount {
                        words
                    }
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                    }
                }
            }
        }
    }
`;
