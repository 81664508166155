import React from 'react';
import { Link } from 'gatsby';

// Components
import PostMetadata from 'components/Post-Metadata';

// Utilities
import { propTypes } from 'shapes/post-link';

const PostLink = ({
    post: {
        frontmatter,
        excerpt,
        wordCount: { words },
        timeToRead,
    },
}) => {
    const { date, path, title } = frontmatter;

    return (
        <div className="post mb-4">
            <Link to={path}>
                <h4>{title}</h4>
            </Link>
            <PostMetadata date={date} words={words} timeToRead={timeToRead} />
            <div className="post-excerpt">{excerpt}</div>
        </div>
    );
};

PostLink.propTypes = propTypes;

export default PostLink;
