import PropTypes from 'prop-types';
import { frontmatter } from './frontmatter';

export const markdownNode =
    PropTypes.shape({
        frontmatter: frontmatter,
        html: PropTypes.string.isRequired,
        excerpt: PropTypes.string,
        timeToRead: PropTypes.number.isRequired,
        wordCount: PropTypes.shape({
            words: PropTypes.number.isRequired,
        }),
    }).isRequired;
