import PropTypes from 'prop-types';
import { frontmatter } from './utils/frontmatter';

export const propTypes = {
    post: PropTypes.shape({
        frontmatter: frontmatter,
        excerpt: PropTypes.string.isRequired,
        wordCount: PropTypes.shape({
            words: PropTypes.number.isRequired,
        }),
        timeToRead: PropTypes.number.isRequired,
    }).isRequired,
};
